import _, { find, some, remove, values } from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getWorkflows, getWorkflowsByProcessID, getWorkflowSteps } from '../../../Core/Service/workflow-service';
import { getWorkflowData, saveWorkflowData } from './../../../Core/Service/workflowData-service';
import { getWorkflowListsAndOps } from '../../../Core/Service/workflowList-service';
import { getBranchById, postJobLock_Save, postResetJobLock_Save } from '../../../Core/Service/branch-screen-service';
import { saveDocumentStamps, saveDocumentStampsV2 } from '../../../Core/Service/workflowJobsDocumentStamp-service';
import moment from 'moment';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import { getDocumentVideoByName, getOcrDocumentHistoryForJob } from '../../../Core/Service/workflowJobDocument-service';
import { useHistory } from 'react-router-dom';
import AppContext from '../../../App/AppContext';
import { AppStorage } from '../../../Core/Service/storage-service';

export const ScreenContext = createContext();

let tempWorkflowDocs = [];

export const MarkingType = {
    Stamp: 1,
    Check: 2,
    Cross: 3,
    Calendar: 4,
    Sign: 5,
    Text: 6,
    NA: 7,
    FreeDrawing: 8,
    DisplayName: 9
};

export const FixedImage = {
    Check: 'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAOpJREFUOE9j/Prr63/uNm4GcsCt7FsMjAwNDP/J0QzTQ7IBmyM2M/iu8IXbSZIBD/IfMMgLyDPMOTOHIXVrKtgQog2AaQZramAE6STegJXBKxnCdMIgGpA0E+eCfwwM/xsh4czdzM3w7d83lDBH8cL/+v8MjI1Qt0GVgcRA4M+/PwyszawYEQY3gOkfE8Pfxr9gBZIdkgwvfr5ggGkGOxXNYKzRiKzhzfc3DCKcIhB/49CMNQyQDYHbQooBIE3EOJ1gSoQZgs/5BKNRmVeZ4e7nu3izCtEpEZcpjI8/PP4vO0GWrAy5M2YnAwDQEVzuLW10/AAAAABJRU5ErkJggg==',
    Cross: 'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAKFJREFUOE+1U8kNgDAMcxgB8eHHQuzABvxYgg8jMBoLsAJGhRZBFBDl6CdSFLu2lQhJommAtkXUq2ug6yCsKqLvo7DbcFlCCPAZekX9RDBNQJIchVk9U4EbFPH6fKV36aoiti0EgA4nEO/65xloEgN8HeIrglcW9uAg2+r5HM5D1J4dSVSIN9fzg00sCmIYbv6nxvIcspxzlgHjGEeSpgtmBnmGSrnWchiJAAAAAElFTkSuQmCC',
    // NA: `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAcNJREFUOE/tlL1rVFEQxc8ZliWxtNt379tgpwhqqWilEhsLlYA2dgEL104LCxU//oOAK9oKgtikEgTRQiRNmggxxSs278576wcoWChLsnfksdmQ9aPaLXO7KeZ3z5wzDDHhxwnzsAsc39FtD9M0uU7amzzvLlfYZtNdNoufQ+i+2qpvmuF7CMWjqvY+aZEIIZSLO2VsA71PemYsp6d/Hc6ybz/StLEEyHIIxdUt4BoZz6+vdz/OzDQOxCirZvZJtfQA+kPoCJBk3QyPVYsrO4He+0MAnqnqwarROfdAxE6Z4YgIz+Z58fqfQICLJOZIO2Nm94cK09TdI2l5rncA0HuXiWDBDLMAyxB0/j9AqXw5GqOdJPmTtLfVyM65VZIXVfWDc+6YCN6TNgvIiRjjtampPY0sy3oVdGRkQFr1ev35xkZvpcoFwENA2mR8kefF/qohTf0CYK3RfbBzw3D+Aqrqk2YzOW3GKt02ya9mJiEUtwHUnHNKWlu1vDv4oLEUo3SKorj0h0I/X6vFd51OuTZYC39BpP+l35cbJG+p6or3fq+IzW1u2suyLMMg/eR4jLJPVZ+OAMdf6QFh9ziM7+TEPfwN4xzRFTcNfGsAAAAASUVORK5CYII=`
    NA: `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAUCAYAAAB4d5a9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAShJREFUeNrsVLGKAkEMfZ5go2Bpp72FvYV/oH9gb2GvYONuoaC1gpWdYK9gr39gaWdpp51Wc/cIuczCyHHCXnM+CJkkm7ydvGUz+byLAAyRHuLMF4lDyvjAH+BN8jrJZALsdsBgkMzRfKzXyRx7aLXaExZ+XWr7vftGtWo5mj5Tr0u915O407Ge2cye8+3puhaLcL7bFb/dim82xR+PQKv1C004oNGwAT6YY/18BopFi1croFwO9wRJ+n3x02ky327LYL2FvjlzqkfwNiFNeB6NbNeqyWbj3PUa1lDh13/UZD4HbjeLKxVbjcZcKbUoFMTYoyv0kc3lokgDXvnxkP3Sn05AqSSD7nc5j8fA5SIEjJdLqRPsITn94WAk7x/kPyaJU+aIPwUYAIMN3CpVOzsAAAAAAElFTkSuQmCC`
};

export const WorkflowJobDetailsProvider = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let ParamProcessId = params.get('ProcessId') || '';
    let ParamPartsWorkflowID = params.get('PartsWorkflowID') || '';
    let ParamInternalTechtechId = params.get('techId') || '';
    let ParamBranchShortName = params.get('branchShortName') || '';
    const froms24 = params.get('froms24') === 'true';
    const S24IsUnallocated = params.get('IsUnallocated') === 'true';
    const isCustRating = params.get('isCustomer') === '1';

    const customerRating = props?.location?.state?.isCustRating || false;

    useEffect(() => {}, []);

    const [widths] = useWindowSize();
    const tabView = widths < 1260 ? true : false;
    const tabSize = widths;
    const { userRoles } = useContext(AppContext);
    const isTechnician = userRoles.includes('technician');

    const [screenState, setScreenState] = useState({
        allFilled: false,
        isReadonly: true,
        showNotesModal: false,
        showHistoryModal: false,
        showReturnReasonModal: false,
        showOcrDocHistory: false,
        documentRotateBy: 0,
        zoomFactor: 1,
        showMailModal: false,
        docInfoToMail: undefined,
        showAllDocModal: false,
        showWarning: false,
        showReturnReasonHistoryModal: false,
        showBarCode: false,
        showCustomerNotes: false,
        showHeaderDetails: false
    });

    const [state, setState] = useState({
        tabView: tabView ? true : false,
        jobId: +props.match.params.jobId,
        selectedWorkflowId: +props.match.params.workflowId,
        technicianJobId: +props.match.params.technicianJobId || null,
        isTechReadonly: props.match.params.isTechReadonly,
        fromPage: props.match.params.frompage || '',
        selectedOcrDocId: undefined,
        selectedOcrDocName: undefined,
        selectedOcrDocHistoryCount: undefined,
        workflows: [],
        workflowSteps: [],
        workflowStepsList: [],
        imagesFromUrl: [],
        videoFromUrl: null,
        isSelectedFilePdf: false,
        loadingDoc: false,
        currentVisibleDocName: undefined,
        hasSubmitedForm: false,
        selectedDocHistory: [],
        docHistoryLoading: true,
        autoLoadDocuments: AppStorage.getAutoLoadDocuments()
    });

    const [stampImages, setStampImages] = useState({
        loading: false,
        loaded: false,
        images: []
    });
    const [warningModal, setWarningModal] = useState({ showModal: false, msg: '' });
    const [btnLoader, setBtnLoader] = useState({ btn1: false, btn2: false });

    const [markingState, setMarkingState] = useState({
        markingType: null,
        isStart: false,
        isContinue: false,
        needModal: false,
        stamps: [],

        //below are for v2
        haveAnySelected: false,
        fabricCanvasData: {},
        base64FromFabric: {}
    });
    let history = useHistory();

    const startMarkingMode = () => {
        setMarkingState((st) => ({ ...st, isStart: true, markingType: null }));
    };

    const stopMarkingMode = () => {
        setMarkingState((st) => {
            let newSt = { ...st };
            newSt.isStart = false;

            if (props.canUseNewStamp) {
                newSt.markingType = null;
                newSt.haveAnySelected = false;
                newSt.fabricCanvasData = {};
                newSt.base64FromFabric = {};
            } else {
                remove(newSt.stamps, (el) => el.isNew);
                newSt.stamps = [...newSt.stamps];
            }
            return newSt;
        });
    };

    const saveCanvasDataForPageNo = (pageNo, fullJson, base64Data, haveAnySelected) => {
        setMarkingState((st) => {
            let newSt = { ...st };
            newSt.markingType = newSt.isContinue ? newSt.markingType : null;
            newSt.fabricCanvasData = { ...newSt.fabricCanvasData, [pageNo]: fullJson };
            newSt.base64FromFabric = { ...newSt.base64FromFabric, [pageNo]: base64Data };
            newSt.haveAnySelected = haveAnySelected;

            return newSt;
        });
    };

    const hasAnythingToSave = () => {
        return values(markingState.fabricCanvasData).some((o) => o.objects.length);
    };

    const markingTypeSelection = (e, typ) => {
        let other;
        switch (typ) {
            case MarkingType.Stamp:
                if (!stampImages.loading && !stampImages.loaded) {
                    setStampImages((st) => ({ ...st, loading: true }));
                    getBranchById(workflowData?.jobBasicDetail?.branchID).then((res) => {
                        const imgs = res.data.stampImages.map((x) => ({ id: x.branchStampID, src: x.branchStampImage }));
                        setStampImages((st) => ({ ...st, loading: false, loaded: true, images: imgs }));
                    });
                }
                other = { isContinue: false, needModal: true };
                break;
            case MarkingType.Sign:
            case MarkingType.Text:
                other = { isContinue: false, needModal: true };
                break;
            case MarkingType.Check:
            case MarkingType.Cross:
            case MarkingType.NA:
                other = { isContinue: true, needModal: false };
                break;
            case MarkingType.Calendar:
                other = { isContinue: false, needModal: false };
                break;
            case MarkingType.DisplayName:
                other = { isContinue: false, needModal: false };
                break;
            case MarkingType.FreeDrawing:
                other = { isContinue: true, needModal: false };
                break;
            default:
                other = { markingType: null, isContinue: false, needModal: false };
                break;
        }
        setMarkingState((st) => ({ ...st, ...other, markingType: typ }));
    };

    const removeStamp = (stampId) => () => {
        setMarkingState((st) => {
            let newSt = { ...st };
            remove(newSt.stamps, (el) => el.id === stampId);
            newSt.stamps = [...newSt.stamps];
            return newSt;
        });
    };

    const addNewStamp = (newStamp, heightWidth) => {
        setMarkingState((st) => {
            let newSt = { ...st };
            newSt.markingType = newSt.isContinue ? newSt.markingType : null;
            newSt.stamps = [...newSt.stamps];
            const last = { ...newStamp, id: moment().valueOf(), isNew: true, info: { ...newStamp.info, ...heightWidth } };
            newSt.stamps.push(last);
            return newSt;
        });
    };

    const setLoadedStamps = (stampData) => {
        setMarkingState((st) => ({ ...st, stamps: stampData || [], markingType: null }));
    };

    const updateStampInfo = (stampId, info) => {
        setMarkingState((st) => {
            let newSt = { ...st };
            let stamp = st.stamps.find((s) => s.id === stampId);
            if (stamp) {
                stamp.info = { ...stamp.info, ...info };
            }
            newSt.stamps = [...newSt.stamps];
            return newSt;
        });
    };

    const saveStampData = async () => {
        if (props.canUseNewStamp) {
            const canvasofPages = { ...markingState.base64FromFabric };
            let res = await saveDocumentStampsV2(state.jobId, state.selectedOcrDocId, state.selectedOcrDocName, canvasofPages);
            if (res.success) {
                stopMarkingMode();
            }
            return res;
        } else {
            const stampObj = { allStamps: markingState.stamps.map((c) => _.omit(c, 'isNew')) };
            let res = await saveDocumentStamps(state.jobId, state.selectedOcrDocId, state.selectedOcrDocName, stampObj);
            if (res.success) {
                setLoadedStamps(stampObj.allStamps);
            }
            return res;
        }
    };

    const [workflowData, setWorkflowData] = useState({
        currentChanges: {},
        fullMergedWorkflowData: {},
        tempMergedData: {},
        workflowDocs: [],
        jobBasicDetail: {},
        hasSomethingToSave: false,
        hasNotes: false
    });

    const updateState = (update) => {
        setState((st) => ({
            ...st,
            ...update
        }));
    };

    const addDocumentImagesData = (ocrDocId, docName, imgBase64Src) => {
        setState((st) => {
            const imagesFromUrl = [...st.imagesFromUrl];
            imagesFromUrl.push(imgBase64Src);
            return {
                ...st,
                selectedOcrDocId: ocrDocId,
                selectedOcrDocName: docName,
                imagesFromUrl
            };
        });
    };

    const hasPrev = (pageNo) => {
        return pageNo > 0;
    };

    const hasNext = (pageNo) => {
        let total = state.imagesFromUrl.length;
        return pageNo < total - 1;
    };

    const dropDownValueSelected = (o) => {
        setWorkflowData((dt) => {
            const newSt = {
                ...dt
            };
            newSt.currentChanges = {
                ...newSt.currentChanges,
                ...o
            };
            newSt.tempMergedData = {
                ...newSt.tempMergedData,
                ...o
            };
            newSt.hasSomethingToSave = !_(newSt.currentChanges)
                .omitBy((a, b) => (newSt.fullMergedWorkflowData[b] || '') === a)
                .isEmpty();
            return newSt;
        });
    };

    const getListOptionById = (id) => {
        const list = state.workflowStepsList.filter((l) => l.id === id);
        return list && list.length === 1 ? list[0].listOptions : [];
    };
    const rotateDoc = (explicit = undefined) => {
        if (explicit === undefined) {
            setScreenState((st) => ({
                ...st,
                documentRotateBy: (st.documentRotateBy + 90) % 360
            }));
        } else {
            setScreenState((st) => ({
                ...st,
                documentRotateBy: explicit
            }));
        }
    };

    const changeZoomFactor = (changeBy) => {
        setScreenState((st) => ({
            ...st,
            zoomFactor: Math.min(2.5, Math.max(0.5, st.zoomFactor + changeBy))
        }));
    };

    useEffect(() => {
        tempWorkflowDocs = [...workflowData.workflowDocs];
    }, [workflowData.workflowDocs]);

    const getDocumentFromOcrDocId = (docId) => {
        return find(tempWorkflowDocs, {
            ocrDocId: docId
        });
    };

    const setDocumentWithOcrDocId = (ocrDocId, docName) => {
        const doc = getDocumentFromOcrDocId(ocrDocId);
        if (doc) {
            doc.docName = docName;
            setWorkflowData((st) => ({
                ...st,
                workflowDocs: [...st.workflowDocs]
            }));
        } else {
            setWorkflowData((st) => ({
                ...st,
                workflowDocs: [
                    ...st.workflowDocs,
                    {
                        ocrDocId,
                        docName
                    }
                ]
            }));
        }
    };

    const showNotes = () => {
        setScreenState((st) => ({
            ...st,
            showNotesModal: true
        }));
    };

    const hideNotesModal = () => {
        setScreenState((st) => ({
            ...st,
            showNotesModal: false
        }));
    };

    const closeWarningModal = (val) => {
        setWarningModal((st) => ({
            ...st,
            showModal: false,
            msg: ''
        }));
    };

    const notesAdded = () => {
        setWorkflowData((st) => ({
            ...st,
            hasNotes: true
        }));
    };

    const showHeaderDetailsHandler = () => {
        setScreenState((st) => ({
            ...st,
            showHeaderDetails: true
        }));
    };

    const closeHeaderDetails = () => {
        setScreenState((st) => ({
            ...st,
            showHeaderDetails: false
        }));
    };

    const showHistory = () => {
        setScreenState((st) => ({
            ...st,
            showHistoryModal: true
        }));
    };

    const showReturnReasonHistory = () => {
        setScreenState((st) => ({
            ...st,
            showReturnReasonHistoryModal: true
        }));
    };
    const hideReturnReasonHistory = () => {
        setScreenState((st) => ({
            ...st,
            showReturnReasonHistoryModal: false
        }));
    };
    const hideHistoryModal = () => {
        setScreenState((st) => ({
            ...st,
            showHistoryModal: false
        }));
    };

    const showReturnReason = () => {
        setScreenState((st) => ({
            ...st,
            showReturnReasonModal: true
        }));
    };

    const hideReturnReason = () => {
        setScreenState((st) => ({
            ...st,
            showReturnReasonModal: false
        }));
    };

    const handleCustomerNotes = (val) => {
        setScreenState((st) => ({
            ...st,
            showCustomerNotes: val
        }));
    };

    const showMail = (docInfoToMail) => {
        setScreenState((st) => ({
            ...st,
            showMailModal: true,
            docInfoToMail
        }));
    };

    const hideMail = () => {
        setScreenState((st) => ({
            ...st,
            showMailModal: false,
            docInfoToMail: undefined
        }));
    };

    const showDownload = (docInfoToMail) => {
        setScreenState((st) => ({
            ...st,
            showDownloadModal: true,
            docInfoToMail
        }));
    };

    const hideDownload = () => {
        setScreenState((st) => ({
            ...st,
            showDownloadModal: false,
            docInfoToMail: undefined
        }));
    };

    const showAllDoc = () => {
        pullWorkflowDataAndSet();
        setScreenState((st) => ({
            ...st,
            showAllDocModal: true
        }));
    };

    const hideAllDoc = () => {
        setScreenState((st) => ({
            ...st,
            showAllDocModal: false
        }));
    };
    const hideWarningMsg = () => {
        setScreenState((st) => ({
            ...st,
            showWarning: false
        }));
    };

    const returnProcessed = async (hasMsg) => {
        setWorkflowData((st) => ({
            ...st,
            hasNotes: hasMsg,
            showReturnReasonModal: false
        }));
        pullWorkflowSteps();
    };

    const pullWorkflowSteps = () => {
        getWorkflowSteps(state.selectedWorkflowId, state.jobId).then((res) => {
            if (res.success) {
                updateState({
                    workflowSteps: res.data.steps
                });
            }
        });
    };

    const pullWorkflowDataAndSet = async (val = null) => {
        // val is true when we want to move workflow on submit
        getWorkflowData(state.jobId).then(async (res) => {
            if (res.success) {
                let jobBasicDetailData = res?.data?.jobBasicDetail || {};
                if (res.data.jobBasicDetail?.lockUser == '') {
                    await postJobLock_Save(state.jobId, state.selectedWorkflowId);
                    jobBasicDetailData.lockUser = 'Job Locked';
                    localStorage.setItem('_JobLock_Save_', true);
                } else if (res.data.jobBasicDetail?.lockUser) {
                    setWarningModal((st) => ({
                        ...st,
                        showModal: true,
                        msg: res.data.jobBasicDetail?.lockUser || 'Job Locked'
                    }));
                }
                setWorkflowData((st) => ({
                    ...st,
                    currentChanges: {},
                    fullMergedWorkflowData: res.data.workflowData,
                    tempMergedData: {
                        ...res.data.workflowData
                    },
                    workflowDocs: res.data.documents,
                    jobBasicDetail: jobBasicDetailData,
                    hasNotes: res.data.hasNotes,
                    hasSomethingToSave: false
                }));

                if (isTechnician) {
                    history.push(
                        `/Job/${res.data.jobBasicDetail?.jobId}/${res.data.jobBasicDetail?.workflowID}${
                            state.technicianJobId ? `/${state.technicianJobId}` : ''
                        }${state.isTechReadonly ? `/${state.isTechReadonly}` : ''}?ProcessId=${ParamProcessId}${customerRating && '&isCustomer=1'}`
                    );
                } else if (res.data.jobBasicDetail?.workflowID && val) {
                    history.push(
                        `/Job/${res.data.jobBasicDetail?.jobId}/${res.data.jobBasicDetail?.workflowID}?ProcessId=${ParamProcessId}${
                            customerRating && '&isCustomer=1'
                        }`
                    );
                }
            }
        });
    };

    const tryToSaveWorkflowData = async (markComplete) => {
        markComplete == false ? setBtnLoader((st) => ({ ...st, btn1: true })) : setBtnLoader((st) => ({ ...st, btn2: true }));
        let res = await saveWorkflowData(
            state.jobId,
            {
                done: markComplete,
                workflowData: workflowData.currentChanges,
                workflowId: state.selectedWorkflowId
            },
            state.technicianJobId
        );
        if (res.success) {
            pullWorkflowDataAndSet(true);
            markComplete == false ? setBtnLoader((st) => ({ ...st, btn1: false })) : setBtnLoader((st) => ({ ...st, btn2: false }));
            markComplete && pullWorkflowSteps();
        }
        return res;
    };

    const showOcrDocHistoryModal = () => {
        setScreenState((st) => ({
            ...st,
            showOcrDocHistory: true,
            showAllDocModal: false
        }));
    };

    const hideOcrDocHistoryModal = () => {
        setScreenState((st) => ({
            ...st,
            showOcrDocHistory: false
        }));
        pullWorkflowDataAndSet();
    };
    const onBarCodeClick = () => {
        setScreenState((st) => ({
            ...st,
            showBarCode: true
        }));
    };
    const onBarCodeClose = () => {
        setScreenState((st) => ({
            ...st,
            showBarCode: false
        }));
    };
    const onJobAssignedUserClick = () => {
        setScreenState((st) => ({
            ...st,
            showJobAssignedUser: true
        }));
    };
    const onJobAssignedUserClose = () => {
        setScreenState((st) => ({
            ...st,
            showJobAssignedUser: false
        }));
    };
    const onVehicleStatusClick = () => {
        setScreenState((st) => ({
            ...st,
            siteVehicle: true
        }));
    };

    const handleHistoryModal = (val) => {
        setScreenState((st) => ({ ...st, history: true }));
    };
    const closeHistoryModal = (val) => {
        setScreenState((st) => ({ ...st, history: false }));
    };

    const handleVehicleStatusClose = (res) => {
        let newSt = {};
        newSt.siteVehicle = false;
        if (res) {
            newSt.isReload = new Date();
        }
        setScreenState((st) => ({
            ...st,
            ...newSt
        }));
    };

    const getDocumentHistory = (jobId, ocrDocId) => {
        return getOcrDocumentHistoryForJob(jobId, ocrDocId);
    };
    const pullDocumentVideoByName = (docName) => {
        return getDocumentVideoByName(docName);
    };

    // useEffect(() => {
    //     setState((st) => ({
    //         ...st,
    //         jobId: +props.match?.params?.jobId,
    //         selectedWorkflowId: +props.match?.params?.workflowId,
    //         technicianJobId: props.match?.params?.technicianJobId || null,
    //         isTechReadonly: props.match?.params?.isTechReadonly,
    //         fromPage: props.match?.params?.frompage || ''
    //     }));
    // }, [props.match?.params]);

    useEffect(() => {
        setState((st) => ({
            ...st,
            jobId: +props.match?.params?.jobId || st.jobId,
            selectedWorkflowId: +props.match?.params?.workflowId === 7 ? '' : +props.match?.params?.workflowId || st.selectedWorkflowId,
            technicianJobId: props.match?.params?.technicianJobId || null,
            isTechReadonly: props.match?.params?.isTechReadonly || st.isTechReadonly,
            fromPage: props.match?.params?.frompage || ''
        }));
    }, [props.match?.params?.workflowId, +props.match?.params?.jobId, props.match?.params?.technicianJobId]);

    // const propsParamsMatch = useCallback(() => {
    //     console.log(+props.match?.params?.workflowId, '+props.match?.params?.workflowId');
    //     setState((st) => {
    //         let newSt = { ...st };
    //         newSt.jobId = +props.match?.params?.jobId;
    //         newSt.selectedWorkflowId = +props.match?.params?.workflowId == 7 ? '' : +props.match?.params?.workflowId;
    //         newSt.technicianJobId = props.match?.params?.technicianJobId || null;
    //         newSt.isTechReadonly = props.match?.params?.isTechReadonly;
    //         newSt.fromPage = props.match?.params?.frompage || '';
    //         return newSt;
    //     });
    // }, [
    //     props.match?.params
    //     // ?.workflowId, +props.match?.params?.jobId
    // ]);

    // useEffect(() => {
    //     propsParamsMatch();
    // }, [propsParamsMatch]);

    useEffect(() => {
        getWorkflowsByProcessID(ParamProcessId || props.ProcessId).then((res) => {
            if (res.success) {
                const buttons = res.data.map((w) => ({
                    id: w.id,
                    label: w.workflowName,
                    isSelected: w.id === state.selectedWorkflowId
                }));
                updateState({
                    workflows: buttons
                });
            }
        });

        // pullWorkflowDataAndSet();
    }, []);

    useEffect(() => {
        if (workflowData.jobBasicDetail.stopFlag) {
            setScreenState((st) => ({
                ...st,
                showWarning: true
            }));
        }
    }, [workflowData.jobBasicDetail.stopFlag]);

    useEffect(() => {
        let fulFormFilled = true;
        state.workflowSteps
            .filter((s) => s.isRequired)
            .forEach((s) => {
                fulFormFilled = fulFormFilled && !!workflowData.tempMergedData[s.jsonPropertyName];
                return fulFormFilled;
            });
        let isReadonlyWorkflow = !some(state.workflowSteps, (s) => s.isEditable);
        setScreenState((st) => ({
            ...st,
            allFilled: fulFormFilled,
            isReadonly: isReadonlyWorkflow
        }));
    }, [workflowData.tempMergedData, state.workflowSteps]);

    useEffect(() => {
        pullWorkflowDataAndSet();
    }, [state.jobId]);

    useEffect(() => {
        if (state.selectedWorkflowId) {
            getWorkflowListsAndOps(state.selectedWorkflowId).then((res) => {
                if (res.success) {
                    updateState({
                        workflowStepsList: res.data
                    });
                }
            });
        }
    }, [state.selectedWorkflowId]);

    useEffect(() => {
        pullWorkflowSteps();
    }, [state.jobId, state.selectedWorkflowId]);

    useEffect(() => {
        if (workflowData?.jobBasicDetail?.lockUser && localStorage.getItem('_JobLock_Save_') == 'true') {
            return async () => {
                await postResetJobLock_Save();
                localStorage.removeItem('_JobLock_Save_');
            };
        }
    }, [workflowData?.jobBasicDetail]);

    return (
        <ScreenContext.Provider
            value={{
                screenState,
                updateState,
                state,
                markingState,
                saveCanvasDataForPageNo,
                hasAnythingToSave,
                stampImages,
                removeStamp,
                addNewStamp,
                startMarkingMode,
                stopMarkingMode,
                markingTypeSelection,
                updateStampInfo,
                setLoadedStamps,
                saveStampData,
                addDocumentImagesData,
                hasPrev,
                hasNext,
                workflowData,
                dropDownValueSelected,
                getListOptionById,
                getDocumentFromOcrDocId,
                setDocumentWithOcrDocId,
                showNotes,
                hideNotesModal,
                notesAdded,
                showHistory,
                hideHistoryModal,
                showReturnReason,
                hideReturnReason,
                returnProcessed,
                tryToSaveWorkflowData,
                showOcrDocHistoryModal,
                hideOcrDocHistoryModal,
                rotateDoc,
                changeZoomFactor,
                showMail,
                hideMail,
                showDownload,
                hideDownload,
                showAllDoc,
                hideAllDoc,
                hideWarningMsg,
                tabView,
                tabSize,
                showReturnReasonHistory,
                hideReturnReasonHistory,
                getDocumentHistory,
                onBarCodeClick,
                onBarCodeClose,
                onJobAssignedUserClick,
                onJobAssignedUserClose,
                handleCustomerNotes,
                handleHistoryModal,
                closeHistoryModal,
                handleVehicleStatusClose,
                onVehicleStatusClick,
                showHeaderDetailsHandler,
                closeHeaderDetails,
                ParamProcessId,
                ParamInternalTechtechId,
                ParamBranchShortName,
                froms24,
                S24IsUnallocated,
                ParamPartsWorkflowID,
                btnLoader,
                pullDocumentVideoByName,
                warningModal,
                closeWarningModal,
                isCustRating
            }}
        >
            {props.children}
        </ScreenContext.Provider>
    );
};
